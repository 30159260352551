import { Component, Show, createResource, useContext } from "solid-js";
import { A, useNavigate, useSearchParams } from "@solidjs/router";
import Button from "../Button";
import { TextFieldInput } from "../inputs";
import { Icon } from "solid-heroicons";
import { checkCircle } from "solid-heroicons/outline";
import { eyeSlash } from "solid-heroicons/solid";
import { createStore } from "solid-js/store";
import { Loader } from "../utility";
import { SiteContext } from "~/utils/contexts";
import { useSessionContext } from "~/utils/contexts";

// TODO:
//  add session/setSession

const SignIn: Component = () => {
  //// const { session, setSession } = useContext(SiteContext);
  const { session, setSession } = useSessionContext();

  const [, setParams] = useSearchParams<{ signIn?: string }>();
  const navigate = useNavigate();
  const [form, setForm] = createStore({
    username: "",
    password: "",
    send: false,
    transfer: false,
    customerNumber: "",
    email: "",
    firstName: "",
    lastName: "",
    telephone: "",
    exclusives: false,
    updates: false,
    error: null as string | null,
  });
  const [login] = createResource(
    () => form.send,
    async () => {
      if (form.transfer === true && form.customerNumber == "") {
        setForm(
          "error",
          "In order to continue setting up your account you need to supply a valid customer number. Please contact Roma support for additional information."
        );
        return;
      }
      setForm("error", null);
      const response = await fetch(
        `${import.meta.env.VITE_ROMA_API}/account/${
          form.transfer ? "transfer" : "login"
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            form.transfer
              ? {
                  Username: form.username,
                  Password: form.password,
                  FirstName: form.firstName,
                  LastName: form.lastName,
                  Telephone: form.telephone,
                  Email: form.email,
                  Exclusive: form.exclusives,
                  Updates: form.updates,
                }
              : {
                  Username: form.username,
                  Password: form.password,
                }
          ),
        }
      );
      const data = await response.json();
      if (response.status !== 200) {
        setForm("send", false);
        switch (data.Code) {
          case "ALREADY_TRANSFERED":
            setForm(
              "error",
              "The username or password you supplied was incorrect."
            );
            break;
          case "INVALID_LOGIN":
            setForm(
              "error",
              "The username or password you supplied was incorrect."
            );
            break;
          case "VALIDATION_ERROR":
            setForm(
              "error",
              "Please enter a correct email address, phone number and other required fields before continuing."
            );
            break;
          case "ACCOUNT_TRANSFERRED":
            setForm(
              "error",
              "This account has already been transferred. Please try logging in with the email you set during the account transfer process."
            );
            break;
          case "EMAIL_REGISTERED":
            setForm(
              "error",
              "The supplied email address is already in use, please try another one."
            );
            break;
          default:
            setForm("error", data.Message);
        }
        return false;
      }
      if (data.Transfer) {
        setForm({
          customerNumber: data.CustomerNumber,
          firstName: data.FirstName,
          lastName: data.LastName,
          telephone: data.Telephone,
          email: data.Email,
          transfer: true,
          send: false,
        });
        return;
      } else if (data.Reset) {
        // Password reset request has been issued
        navigate(`/forgot-password?code=${data.Code}&email=${data.Email}`);
        return;
      }
      //! setSession("name", data.FirstName);
      //! setSession("token", data.Token);
      setTimeout(() => setParams({ signIn: undefined }), 1250);
      const cartsRequest = await fetch(
        `${import.meta.env.VITE_ROMA_API}/cart`,
        {
          headers: {
            Authorization: `Bearer ${data.Token}`,
          },
        }
      );
      const carts = await cartsRequest.json();
      if (carts.Total != 0) {
        //! setSession("cart_id", carts.Results[0].ID);
      } else {
        const newCartRequest = await fetch(
          `${import.meta.env.VITE_ROMA_API}/cart`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${data.Token}`,
            },
            body: JSON.stringify({}),
          }
        );
        const newCart = await newCartRequest.json();
        //! setSession("cart_id", newCart.ID);
      }
      return true;
    },
    {
      initialValue: false,
      ssrLoadFrom: "initial",
    }
  );
  const Transfer: Component = () => (
    <>
      <h3 class="text-xl font-bold text-center">Setup Account</h3>
      <p class="text-roma-dark-grey">
        Welcome to the new Roma Moulding website. You may have noticed that we
        have a new website. From now on you will use your e-mail as the primary
        account identifier.
      </p>
      <p class="text-roma-dark-grey">
        Please review the following details of your personal account before
        submitting. Note these are only details relevant to your personal
        website account:
      </p>
      {form.error}
      <Show when={form.error}>
        <div class="border-2 border-red-500 text-red-500 p-3 rounded-md">
          {form.error}
        </div>
      </Show>
      <div class="space-y-3">
        <TextFieldInput
          name="transferemail"
          class="text-base"
          label="Email"
          type="email"
          value={form.email}
          required={true}
          disabled={login.loading}
          onChange={(val) => setForm("email", val)}
        />
        <TextFieldInput
          name="firstname"
          class="text-base"
          label="First Name"
          type="text"
          value={form.firstName}
          required={true}
          disabled={login.loading}
          onChange={(val) => setForm("firstName", val)}
        />
        <TextFieldInput
          name="lastname"
          class="text-base"
          label="Last Name"
          type="text"
          value={form.lastName}
          required={true}
          disabled={login.loading}
          onChange={(val) => setForm("lastName", val)}
        />
        <TextFieldInput
          name="telephone"
          class="text-base"
          label="Telephone"
          value={form.telephone}
          type="text"
          required={true}
          disabled={login.loading}
          onChange={(val) => setForm("telephone", val)}
        />
        <TextFieldInput
          name="customernumber"
          class="text-base"
          label="Customer Number"
          type="text"
          value={form.customerNumber}
          required={true}
          disabled={true}
        />
        <div class="space-y-4 py-5">
          <label class="flex items-center cursor-pointer">
            <input
              type="checkbox"
              onClick={(evt) => setForm("updates", evt.currentTarget.checked)}
              class="sr-only peer"
            />
            <div class="w-4 h-4 mr-2 border peer-checked:bg-roma-blue peer-checked:border-0" />
            <div class="text-sm">
              Subscribe to Critical Updates (Recommended)
              <p class="text-xs text-gray-500">
                Receive service emails regarding service outages, changes in
                hours, policy update.
              </p>
            </div>
          </label>
          <label class="flex items-center cursor-pointer">
            <input
              type="checkbox"
              onClick={(evt) =>
                setForm("exclusives", evt.currentTarget.checked)
              }
              class="sr-only peer"
            />
            <div class="w-4 h-4 mr-2 border peer-checked:bg-roma-blue peer-checked:border-0" />
            <div class="text-sm">
              Subscribe to Exclusive Discounts
              <p class="text-xs text-gray-500">
                Be the first to know about sales and discounts on Roma Moulding
                products.
              </p>
            </div>
          </label>
        </div>
        <Button
          class="flex mt-4 gap-2 relative w-full text-base"
          disabled={login.loading}
          type="submit"
        >
          <div>Complete Setup</div>
          <Loader show={login.loading} height="5" width="5" />
        </Button>
      </div>
    </>
  );
  return (
    <Show
      when={login.latest !== true}
      fallback={
        <div class="flex p-10 justify-center items-center gap-3 text-xl">
          <Icon path={checkCircle} class="w-12" />
          Welcome back {session.name}!
        </div>
      }
    >
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          setForm("send", true);
        }}
        class="flex flex-col gap-6"
      >
        <Show fallback={<Transfer />} when={!form.transfer}>
          <div>
            <h3 class="text-xl font-bold text-center mb-2">Sign In</h3>
            <p class="text-roma-dark-grey text-center">
              To access ordering and pricing, please sign into your account.
            </p>
          </div>
          <Show when={form.error}>
            <div class="border-2 border-red-500 text-red-500 p-3 rounded-md">
              {form.error}
            </div>
          </Show>
          <TextFieldInput
            focusOnLoad
            name="username"
            class="text-base"
            label="Username/Email"
            autocomplete="email"
            type="text"
            required={true}
            disabled={login.loading}
            onChange={(val) => setForm("username", val)}
          />
          <TextFieldInput
            name="current-password"
            class="text-base"
            label="Password"
            type="password"
            required={true}
            icon={eyeSlash}
            disabled={login.loading}
            autocomplete="current-password"
            onChange={(val) => setForm("password", val)}
          />
          <div class="flex justify-end">
            <A href="/forgot-password" class="text-sm text-roma-blue font-bold">
              Forgot Password?
            </A>
          </div>
          <p class="text-sm text-roma-dark-grey text-center">
            By logging in, you agree to Roma Moulding's{" "}
            <A href="/privacy-policy" class="underline">
              Privacy Policy
            </A>
            .
          </p>
          <Button
            tabIndex="3"
            class="flex gap-2 relative w-full text-base"
            disabled={login.loading}
            type="submit"
          >
            <div>Sign In</div>
            <Loader show={login.loading} height="5" width="5" />
          </Button>
          <p class="text-sm font-medium text-center">
            Not a partner?{" "}
            <A href="/support/partner" class="underline">
              Join us
            </A>
          </p>
        </Show>
      </form>
    </Show>
  );
};

export default SignIn;
