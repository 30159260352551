import { FlowComponent } from "solid-js";
import { Dynamic } from "solid-js/web";
import { A } from "@solidjs/router";


const Button: FlowComponent<{
  href?: string;
  onClick?: () => void;
  tabIndex?: string;
  style?: string;
  type?: string;
  class?: string;
  target?: string;
  disabled?: boolean;
  disabledClass?: string;
  generic?: boolean;
  download?: boolean;
  rootClass?: string;
  noScroll?: boolean;
}> = (props) => {
  const buttonStyle = {
    outline: "bg-transparent text-black hover:bg-black hover:text-white",
    solid: "bg-black text-white hover:bg-white hover:text-black",
    solidWhite: "!border-0 bg-white text-black hover:bg-black hover:text-white",
  };
  return (
    <div classList={{ "cursor-not-allowed": !!props.disabled, [`${props.rootClass}`] : !!props.rootClass }}>
      <Dynamic
        tabIndex={props.tabIndex}
        component={
          props.href && !props.generic
            ? A
            : props.href && props.generic
            ? "a"
            : "button"
        }
        noScroll={props.noScroll}
        href={props.href}
        download={props.download}
        onClick={props.onClick}
        class={`border border-black transition-colors px-1 md:px-3 py-2 rounded-full flex items-center justify-center 
          ${
            props.style == "outline"
              ? buttonStyle.outline
              : props.style == "solidWhite"
              ? buttonStyle.solidWhite
              : buttonStyle.solid
          }
        `}
        classList={{
          [`${props.class}`]: !!props.class,
          "border-0  pointer-events-none": !!props.disabled,
          "bg-roma-medium-grey": !!props.disabled && !props.disabledClass,
          [`${props.disabledClass}`]: !!props.disabledClass && !!props.disabled,
        }}
        type={props.type}
        target={props.target}
      >
        {props.children}
      </Dynamic>
    </div>
  );
};

export default Button;
