import {
  Component,
  Show,
  useContext,
  createEffect,
  createSignal,
  onMount,
  onCleanup,
  Suspense,
  splitProps,
} from "solid-js";
import { isServer } from "solid-js/web";
// import { useSearchParams } from "solid-start";
import { AccessorWithLatest, useSearchParams } from "@solidjs/router";
// import { SiteContext } from "~/utils/contexts";
import { useSiteContext, useSessionContext } from "~/utils/contexts";
// import { Motion } from "@motionone/solid";
import { Motion } from "solid-motionone";
import { Icon } from "solid-heroicons";
import { xMark } from "solid-heroicons/outline";

import { SearchBar } from "./SearchBar";
import { SearchResults } from "./SearchResults";

// import searchLogic from "./searchLogic";
import { searchLogic } from "~/utils/searchLogic";

// import iconPath from "~/utils/icons";
import { iconPath } from "~/utils/icons";
import { Img } from "../image";

import { type SearchResponse } from "~/utils/searchLogic";

type HeaderSearchProps = {
  removeNav: VoidFunction;
};

export const HeaderSearch: Component<HeaderSearchProps> = () => {
  const [showModal, setShowModal] = createSignal(true);
  const {
    searchValue,
    search,
    setSearchValue,
    searchResults,
    //suggestions
  } = searchLogic();

  const {
    headerVisible: [headerVisible],
  } = useSiteContext();
  const { session, setSession } = useSessionContext();

  // const {
  //   // headerVisible: [headerVisible],
  // //   session,
  // //   setSession,
  // // } = useContext(SiteContext);
  const [_, setParams] = useSearchParams();

  createEffect(() => {
    if (headerVisible() == false) setParams({ search: undefined });
  });

  // ! Removed due to some unwanted behaviour - when scrolled down on
  // ! a page, and user scrolls up to reveal header, search then flickers
  // ! when interacted with.
  // Lock scrolling on body to prevent accidentally closing search
  // if (!isServer) {
  //   onMount(() => {
  //     window.document.body.style.overflow = "hidden";
  //   });
  //   onCleanup(() => {
  //     window.document.body.style.overflow = "auto";
  //   });
  // }

  const registerPopup = (id: string) => {
    setSession(`searchDemo_${id}`, "true");
    return true;
  };

  return (
    <>
      {/* <Show when={!session[`searchDemo_1`]}>
        <InfoPopUp
          showModal={showModal}
          onClose={() => {
            setShowModal(false);
            registerPopup("1"); 
          }}
        />
      </Show> */}
      <Motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        class={`bg-white shadow-xl absolute inset-x-0 top-9 min-h-[30vh] max-h-[calc(100vh_-_2.25rem)] sm:max-h-[90vh] grid grid-rows-[min-content,_1fr]`}
      >
        <Motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{
            opacity: 0,
            y: -40,
            transition: {
              duration: 0.25,
              delay: 0,
            },
          }}
          transition={{ duration: 0.25, delay: 0.25 }}
          // class="lg:max-w-xl xl:max-w-3xl mx-auto relative px-2 sm:px-2 lg:px-10 max-sm:pt-16 max-sm:pb-4 sm:pt-16 sm:pb-2 lg:pt-2 lg:pb-4 flex "
          class="relative  mx-auto px-6 lg:px-10 max-sm:py-4  sm:py-4 flex justify-center items-center w-full"
        >
          <SearchBar onInput={search} searchValue={searchValue} />
          <Motion.button
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            onClick={() => setParams({ search: undefined })}
            class=" right-0 py-2 flex items-center text-roma-dark-grey hover:text-black"
          >
            <Icon path={xMark} class="ml-1 w-8" />
          </Motion.button>
          <div class="hidden lg:block absolute left-8">
            <Img
              src={iconPath["Roma Logo"]}
              layout="constrained"
              width={36}
              aspectRatio={1}
            />
          </div>
        </Motion.div>

        <SearchResults
          // suggestions={suggestions}
          searchResults={searchResults as AccessorWithLatest<SearchResponse>}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Motion.div>
    </>
  );
};
