import { FlowComponent, JSX, Accessor, Show } from "solid-js";
import { Dialog } from "@kobalte/core";
import { Icon } from "solid-heroicons";
import { xMark } from "solid-heroicons/outline";
import "./modal.css";

type ModalProps = {
  description?: string;
  title?: string;
  overlayClass?: string;
  contentClass?: string;
  triggerClass?: string;
  width?:
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "7xl"
    | "full";
} & (
  | {
      trigger?: undefined;
      open: Accessor<boolean>;
      onClose: VoidFunction;
    }
  | {
      trigger: JSX.Element | string;
      open?: never;
      onClose?: never;
    }
);

export const Modal: FlowComponent<ModalProps> = (props) => {

  const overlayClass = () => {
    const base =
      "dialog__overlay fixed inset-0 z-30 bg-white/70 backdrop-blur-sm";
    return `${base} ${props.overlayClass ?? ""}`;
  };
  const positionerClass = () => {
    const base = "fixed inset-0 z-30 flex justify-center items-center";
    return base;
  };
  const contentClass = () => {
    const width = () => {
      switch (props.width) {
        case "sm":
          return "max-w-[min(calc(100vw-16px),_384px)]";
        case "md":
          return "max-w-[min(calc(100vw-16px),_448px)]";
        case "lg":
          return "max-w-[min(calc(100vw-16px),_512px)]";
        case "xl":
          return "max-w-[min(calc(100vw-16px),_576px)]";
        case "2xl":
          return "max-w-[min(calc(100vw-16px),_672px)]";
        case "3xl":
          return "max-w-[min(calc(100vw-16px),_768px)]";
        case "4xl":
          return "max-w-[min(calc(100vw-16px),_896px)]";
        case "5xl":
          return "max-w-[min(calc(100vw-16px),_1024px)]";
        case "6xl":
          return "max-w-[min(calc(100vw-16px),_1152px)]";
        case "7xl":
          return "max-w-[min(calc(100vw-16px),_1280px)]";
        case "full":
          return "w-calc(100vw-16px)";
        default:
          return "max-w-[min(calc(100vw-16px),_512px)]";
      }
    };
    const base = `z-30 border rounded-lg p-4 md:p-8 md:pb-12 bg-white shadow-lg mx-2 ${width()} overflow-auto`;
    return `${base} ${props.contentClass ?? ""} `;
  };

  return (
    <Dialog.Root open={props.open ? props.open() : undefined}>
      <Show when={props.trigger}>
        <Dialog.Trigger class={props.triggerClass ?? ""}>{props.trigger}</Dialog.Trigger>
      </Show>
      <Dialog.Portal>
        <Dialog.Overlay class={`${overlayClass()} `} />
        <div class={positionerClass()}>
          <Dialog.Content
            class={contentClass()}
            onEscapeKeyDown={props.onClose}
            onPointerDownOutside={props.onClose}
          >
            <div class="flex justify-between items-baseline">
              <Show when={props.title}>
                <Dialog.Title class="text-xl font-semibold mb-3">
                  {props.title}
                </Dialog.Title>
              </Show>
              <Dialog.CloseButton class="ml-auto" onClick={props.onClose}>
                <Icon path={xMark} class="w-6 h-6" />
              </Dialog.CloseButton>
            </div>
            <Show when={props.description}>
              <Dialog.Description>{props.description}</Dialog.Description>
            </Show>
            <Show when={props.children}>
              <>{props.children}</>
            </Show>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};


